import { Navigation } from 'swiper/modules';
import Swiper from 'swiper';

class EventsSlider {

  constructor(node) {

    this.swiper = node;

    this.initSwiper();

    node.swiper = this;

  }


  initSwiper() {

    const swiper = new Swiper(this.swiper, {
      modules: [
        Navigation
      ],
      slidesPerView: 1,
      spaceBetween: 24,
      navigation: {
        nextEl: this.swiper.querySelector('.swiper-button-next'),
        prevEl: this.swiper.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        768: {
          spaceBetween: 24,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 32,
          slidesPerView: 2,
        }
      }
    });
}


}

export default EventsSlider;
