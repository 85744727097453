export default {

  init() {

    const waitForItems = () => {

      const items = document.querySelectorAll('.whr-item');

      if (items.length > 0) {

          items.forEach((item) => {

            let href = item.querySelector('a').getAttribute('href');

            item.addEventListener('click', (e) => {
              e.preventDefault();
              window.open(href, '_blank');
            });

          });

      } else {

        setTimeout(waitForItems, 100);

      }

    };

    waitForItems();

  },

};
